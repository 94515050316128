<template>
  <div>
    <vside
      @hide="fechar()"
      :active="modalAvaliacaoSideBar"
      :item="{}"
      size="huger"
      textSucces="Finalizar"
    >
      <div slot="body">
        <div id="avaliacaoSideBar" class="mb-1 vs-con-loading__container">
          <div
            class="mt-1"
            v-if="tableData.length == 0 || tableData.length == undefined"
          >
            <div class="pt-20 con-colors">
              <ul class="pt-20">
                <li class="danger-box">
                  <h2 class="p-5" style="color: white !important">
                    Nenhum Serviço
                  </h2>
                </li>
              </ul>
            </div>
          </div>
          <div v-else>
            <div>
              <div>
                <vx-card card-background="dark">
                  <div class="p-2" style="color: white !important">
                    <span style="font-size: 20px">
                      <b style="text-transform: uppercase">{{ row.tecnico }}</b>
                      - {{ avaliacao.avaliacao }}:&nbsp;
                    </span>
                    <span class="ml-2" style="font-size: 18px; color: #ffca00">
                      <b
                        >{{ tableData.length }} serviços /
                        {{
                          tableData.reduce((total, item) => {
                            return total + item.horas_exec;
                          }, 0)
                        }}
                        hora(s)</b
                      >
                    </span>
                  </div>
                </vx-card>
              </div>
            </div>
            <vs-table
              maxHeight="79vh"
              pagination
              max-items="10"
              :data="tableData"
              stripe
            >
              <template slot="thead">
                <vs-th style="width: 7%" id="center">Contrato</vs-th>
                <vs-th style="width: 7%" id="center">Os</vs-th>
                <vs-th style="width: 7%" id="center">Rota</vs-th>
                <vs-th style="width: 7%" id="center">Cliente</vs-th>
                <vs-th style="width: 7%" id="center">Data Competência</vs-th>
                <vs-th style="width: 7%" id="center">Data Execução</vs-th>
                <vs-th style="width: 7%" id="center">Data Remarcação</vs-th>
                <vs-th style="width: 7%" id="center">Horas</vs-th>
                <vs-th style="width: 7%" id="center">Técnicos</vs-th>
                <vs-th style="width: 7%" id="center">Horário</vs-th>
                <vs-th style="width: 7%" id="center">Tipo</vs-th>
                <vs-th style="width: 3%" id="center">Status Os</vs-th>
              </template>
              <template slot-scope="{ data }">
                <vs-tr
                  :key="row.contrato"
                  v-for="row in data"
                  :style="
                    row.executado == 1
                      ? 'color: green; background-color: #e8fce8 !important'
                      : row.executado == 2
                      ? 'color: #dd3522;background-color:#f5efef !important;'
                      : ''
                  "
                >
                  <vs-td class="text-center" style="font-size: 11.5px">
                    <a
                      v-if="row.id_contrato > 0"
                      :href="'contrato?id=' + row.id_contrato"
                      style="font-size: 12px; color: darkblue; cursor: pointer"
                    >
                      <b>{{ row.id_contrato > 0 ? row.id_contrato : "..." }}</b>
                    </a>
                  </vs-td>
                  <vs-td class="text-center" style="font-size: 11.5px">
                    {{ row.id }}
                  </vs-td>
                  <vs-td class="text-center" style="font-size: 11.5px">
                    {{ row.id_rota ? row.id_rota : "--" }}
                  </vs-td>
                  <vs-td class="text-center" style="font-size: 11.5px">
                    {{ row.cliente }}
                  </vs-td>

                  <vs-td class="text-center" style="font-size: 11.5px">
                    {{ row.data_competencia ? row.data_competencia : "--" }}
                  </vs-td>
                  <vs-td class="text-center" style="font-size: 11.5px">
                    {{ row.data_execucao ? row.data_execucao : "--" }}
                  </vs-td>
                  <vs-td class="text-center" style="font-size: 11.5px">
                    {{ row.data_remarcacao ? row.data_remarcacao : "--" }}
                  </vs-td>
                  <vs-td class="text-center" style="font-size: 11.5px">
                    {{ row.horas_exec }}
                  </vs-td>
                  <vs-td class="text-center" style="font-size: 11.5px">
                    {{ row.tecnicos_exec }}
                  </vs-td>
                  <vs-td class="text-center" style="font-size: 11.5px">
                    {{ row.horario }}
                  </vs-td>
                  <vs-td class="text-center" style="font-size: 11.5px">
                    {{ row.tipoServico }}
                  </vs-td>
                  <vs-td class="text-center" style="font-size: 12px">
                    <b>
                      {{
                        row.executado == 1
                          ? "Executado"
                          : row.executado == 2
                          ? "Não Executado"
                          : row.remarcado
                          ? "Remarcado"
                          : row.confirmado
                          ? "Confirmado"
                          : "Pendente"
                      }}
                    </b>
                  </vs-td>
                </vs-tr>
              </template>
            </vs-table>
          </div>
        </div>
      </div>
    </vside>
  </div>
</template>
<script>
import components from "@/components/default/exports.js"
export default {
  props: {
    avaliacao: null,
    filtro: null,
    row: null
  },
  data () {
    return {
      tableData: [],
      modalAvaliacaoSideBar: false,
      settings: {
        maxScrollbarLength: 160,
        wheelSpeed: 1,
        height: 1000
      }
    }
  },
  methods: {
    async fechar () {
      this.modalAvaliacaoSideBar = false
    },
    async getData () {
      await this.$vs.loading({
        container: "#avaliacaoSideBar",
        type: "sound",
        scale: 0.6
      })
      try {
        this.tableData = await this.$http.post(
          "getAvaliacaoPCOSideBar",
          {
            filtro: this.filtro,
            id_tecnico: this.row.id,
            label: this.avaliacao.avaliacao
          }
        )
      } catch (err) {
        const error = this.$httpErrors(err)
        this.$vs.notify(error)
      } finally {
        await this.$vs.loading.close(
          "#avaliacaoSideBar > .con-vs-loading"
        )
      }
    }
  },
  async mounted () {
    this.modalAvaliacaoSideBar = true
    await this.getData()
  },
  components: {
    ...components
  }
};
</script>
<style lang="scss">
thead tr th#center {
  .vs-table-text {
    justify-content: center !important;
  }
}
</style>
