<template>
  <div
    class="w-full m-0 p-0 vs-con-loading__container"
    :id="'avaliacaoCard' + row.id"
  >
    <vx-card no-shadow class="w-full border ml-2 mb-2 p-0">
      <vs-row
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="center"
        vs-w="12"
      >
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="1">
          <vs-avatar size="100px" :src="row.foto" />
        </vs-col>

        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="11">
          <div class="w-full">
            <vs-col
              vs-w="2"
              v-for="(avaliacao, index) in avaliacaoInfo"
              :key="index"
            >
              <div class="mx-1" @click="openAvaliacaoSideBar(avaliacao)">
                <vx-card noShadow style="cursor: pointer" class="border">
                  <vs-col
                    vs-w="12"
                    vs-type="flex"
                    vs-justify="center"
                    vs-align="center"
                  >
                    <h6>{{ avaliacao.avaliacao }}</h6>
                  </vs-col>
                  <vs-divider
                    border-style="dotted"
                    color
                    class="py-0 mb-0 my-0"
                  ></vs-divider>
                  <div class="w-full my-1">
                    <h6 class="text-center">
                      {{ avaliacao.qtd }}
                      {{
                        avaliacao.qtd > 1 || avaliacao.qtd == 0
                          ? " Avaliações"
                          : " Avaliação"
                      }}
                      -
                      {{
                        avaliacao.totalHoras > 0
                          ? avaliacao.totalHoras +
                            (avaliacao.totalHoras > 1 ? " Horas" : " Hora")
                          : "0 horas"
                      }}
                    </h6>
                  </div>
                  <div class="w-full my-1">
                    <h6
                      class="text-center"
                      style="color: #00bba2; font-size: 14px"
                    >
                      {{ avaliacao.pontos }}
                      {{
                        avaliacao.pontos > 1 || avaliacao.pontos == 0
                          ? " Pontos"
                          : " Ponto"
                      }}
                      e
                      {{ $currency(parseFloat(avaliacao.comissao)) }}
                    </h6>
                  </div>
                  <div class="w-full my-1">
                    <h6 class="text-center"></h6>
                  </div>
                </vx-card>
              </div>
            </vs-col>
          </div>
        </vs-col>
      </vs-row>
    </vx-card>
    <component
      v-bind:is="componentModal"
      :key="reRenderComponentModal"
      :filtro="filtro"
      :row="row"
      :avaliacao="avaliacao"
    />
  </div>
</template>
<script>
import avaliacaoSideBar from "./avaliacaoSideBar"
export default {
  props: {
    row: null,
    filtro: null
  },
  data () {
    return {
      avaliacaoInfo: {},
      avaliacao: {},
      componentModal: "",
      reRenderComponentModal: 0
    }
  },
  methods: {
    async getAvaliacaoPCOInfo () {
      await this.$vs.loading({
        container: "#avaliacaoCard" + this.row.id,
        scale: 0.6
      })
      try {
        this.avaliacaoInfo = await this.$http.post("getAvaliacaoPCOInfo", {
          filtro: this.filtro,
          id_tecnico: this.row.id
        })
      } catch (err) {
        const error = this.$httpErrors(err)
        this.$vs.notify(error)
      } finally {
        await this.$vs.loading.close(
          "#avaliacaoCard" + this.row.id + " > .con-vs-loading"
        )
      }
    },
    async openAvaliacaoSideBar (avaliacao) {
      this.avaliacao = avaliacao
      this.componentModal = "avaliacaoSideBar"
      this.reRenderComponentModal++
    }
  },
  async mounted () {
    await this.getAvaliacaoPCOInfo()
  },
  components: {
    avaliacaoSideBar
  }
}
</script>
